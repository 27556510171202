import styled from 'styled-components';

export const ContactComponentWrapper = styled.div`
  & + &,
  &.top-padding {
    padding-top: 100px;
  }
  &.bottom-padding {
    padding-bottom: 100px;
  }
`;
