import React, { useState } from 'react';

import { EmailTemplate } from '@lerna-monorepo/common/enums/emailTemplate.enum';
import { NameValueString } from '@lerna-monorepo/common/interfaces/nameValueString.types';
import { MapSection } from '@lerna-monorepo/common/components/mapSection/mapSection.component';
import { SmallImageHeader } from '@lerna-monorepo/common/components/smallImageHeader/smallImageHeader.component';
import { ContactFormSection } from '@lerna-monorepo/common/components/contactFormSection/contactFormSection.component';
import { ContactBoxesSection } from '@lerna-monorepo/common/components/contactBoxesSection/contactBoxesSection.component';
import { ContactDataWithImage } from '@lerna-monorepo/common/components/contactDataWithImage/contactDataWithImage.component';
import { CurrentLanguageContext } from '@lerna-monorepo/common/contexts';
import { ApiService } from '../../services/api.service';
import { renderWhenTrue } from '@lerna-monorepo/common/utils';
import { ContactPageData } from './contact.types';
import { ContactComponentWrapper } from './contact.styled';

const ContactPage: React.FC<ContactPageData> = (pageData: ContactPageData) => {
  const {
    data,
    distributionPoints,
    salePoints,
    currentLanguage,
  } = pageData.pageContext;
  const [formStatusLabel, setFormStatusLabel] = useState('');
  const [formStatus, setFormStatus] = useState(null);
  const {
    distributionSection,
    mapImageSection,
    contactFormSection,
    headerImage,
    saleMapSection,
  } = data;

  const contactFormSubmitHandler = (data: NameValueString[]) => {
    const api = new ApiService();
    const dataWithTitle = {
      ...data,
      template: EmailTemplate.Contact,
    };

    api
      .sendContactFormMessage(dataWithTitle)
      .then(() => {
        setFormStatus(true);
        setFormStatusLabel(contactFormSection.successMessage);
        setTimeout(() => setFormStatus(null), 2000);
      })
      .catch(() => {
        setFormStatus(false);
        setFormStatusLabel(contactFormSection.failMessage);
        setTimeout(() => setFormStatus(null), 2000);
      });
  };

  const renderMapSection = renderWhenTrue(() => (
    <ContactComponentWrapper>
      <MapSection
        sectionHeader={saleMapSection.sectionHeader}
        sectionSubheader={saleMapSection.sectionSubheader}
        rentLabel={saleMapSection.rentLabel}
        saleLabel={saleMapSection.saleLabel}
        salePoints={salePoints}
        currentLanguage={currentLanguage}
      />
    </ContactComponentWrapper>
  ));

  return (
    <CurrentLanguageContext.Provider value={currentLanguage}>
      <SmallImageHeader image={headerImage} />
      <ContactComponentWrapper className="top-padding">
        <ContactDataWithImage
          data={mapImageSection}
          currentLanguage={currentLanguage}
        />
      </ContactComponentWrapper>
      <ContactComponentWrapper>
        <ContactFormSection
          sectionHeader={contactFormSection.sectionHeader}
          contactFormRows={contactFormSection.contactFormRows}
          agreements={contactFormSection.agreements}
          sendButtonLabel={contactFormSection.sendButtonLabel}
          onSubmit={contactFormSubmitHandler}
          status={formStatus}
          statusLabel={formStatusLabel}
        />
      </ContactComponentWrapper>
      {renderMapSection(saleMapSection.isEnabled)}
      <ContactComponentWrapper className="bottom-padding">
        <ContactBoxesSection
          sectionHeader={distributionSection.sectionHeader}
          distributionDepartments={distributionPoints}
          currentLanguage={currentLanguage}
        />
      </ContactComponentWrapper>
    </CurrentLanguageContext.Provider>
  );
};

export default ContactPage;
