import { NameValueString } from '@lerna-monorepo/common/interfaces/nameValueString.types';

export class ApiService {
  private apiUrl = process.env.API_URL;

  private post(path: string, body: unknown): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', path, true);
      xhr.addEventListener('load', function() {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      });
      xhr.send(JSON.stringify(body));
    }).then(res => res);
  }

  public sendContactFormMessage(data: NameValueString[]): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const url = `${this.apiUrl}/wp-json/contact-form/v1/send`;
      this.post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
